<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Цуглуулалтын төлбөрийн тохиргоо
      </h3>
      <div class="action-section">
        <el-button type="primary" @click="addNew">Үүсгэх</el-button>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <el-table
            :data="collectionFeeConfigList"
            size="mini"
            style="width: 95%,margin: 100vh;"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column label="Доод тоо">
              <template slot-scope="scope">
                {{ scope.row.min_count || "Хамаагүй" }}
              </template>
            </el-table-column>
            <el-table-column label="Доод тоо">
              <template slot-scope="scope">
                {{ scope.row.max_count || "Хамаагүй" }}
              </template>
            </el-table-column>
            <el-table-column label="Доод үнийн дүн">
              <template slot-scope="scope">
                {{ scope.row.min_total || "Хамаагүй" }}
              </template>
            </el-table-column>
            <el-table-column label="Дээд үнийн дүн">
              <template slot-scope="scope">
                {{ scope.row.max_total || "Хамаагүй" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="percent"
              label="Хэрэглэгчээс авах"
            ></el-table-column>
            <el-table-column
              prop="driver_percent"
              label="Хүргэгчид өгөх"
            ></el-table-column>
            <el-table-column prop="percent" label="Үндсэн">
              <template slot-scope="scope">
                {{ scope.row.is_default ? "Тийм" : "Үгүй" }}
              </template>
            </el-table-column>
            <el-table-column prop="is_show" label="Засах">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-edit"
                  style="margin-top: 10px;"
                  :disabled="scope.row.is_default"
                  @click="edit(scope.row)"
                  >Засах</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="is_show" label="Устгах">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-edit"
                  :disabled="scope.row.is_default"
                  @click="deleteConfig(scope.row.id)"
                  >Устгах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      v-if="selCollectionFeeconfig"
      :title="selCollectionFeeconfig.id > 0 ? 'Засах' : 'Үүсгэх'"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <el-form
        :inline="true"
        ref="ruleForm"
        :model="selCollectionFeeconfig"
        :rules="rules"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-form-item label="Доод тоо:" prop="min_count">
          <el-input
            v-model="selCollectionFeeconfig.min_count"
            placeholder="Доод тоог оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Дээд тоо:" prop="max_count">
          <el-input
            v-model="selCollectionFeeconfig.max_count"
            placeholder="Дээд тоог оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Доод үнэ:" prop="min_total">
          <el-input
            v-model="selCollectionFeeconfig.min_total"
            placeholder="Доод үнээ оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Дээд үнэ:" prop="max_total">
          <el-input
            v-model="selCollectionFeeconfig.max_total"
            placeholder="Дээд үнээ оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Хэрэглэгчээс авах:" prop="percent">
          <el-input
            v-model="selCollectionFeeconfig.percent"
            placeholder="Хэрэглэгчээс авах хувь оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Хүргэгчид өгөх:" prop="driver_percent">
          <el-input
            v-model="selCollectionFeeconfig.driver_percent"
            placeholder="Хүргэгчид өгөх хувь оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Харилцагч сонгох:" prop="outlet_id">
          <el-select
            v-model="selCollectionFeeconfig.outlet_id"
            multiple
            placeholder="Select"
          >
            <el-option
              v-for="outlet in availableOutlets"
              :key="outlet.id"
              :label="outlet.name"
              :value="outlet.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="success" @click="createOrUpdate">{{
          selCollectionFeeconfig.id ? "Засах" : "Үүсгэх"
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "../helpers/services";
export default {
  created() {
    this.getCollectionFeeConfigList();
  },
  data() {
    return {
      availableOutlets: [],
      selCollectionFeeconfig: {
        min_count: "",
        max_count: "",
        min_total: "",
        max_total: "",
        percent: 10,
        driver_percent: 5,
        is_default: false,
        outlet_id: ["ALL"]
      },
      dialogVisible: false,
      rules: {
        percent: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        driver_percent: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ]
      },
      collectionFeeConfigList: []
    };
  },
  methods: {
    edit(item) {
      this.selCollectionFeeconfig = item;
      if (this.$refs.ruleForm) this.$refs.ruleForm.clearValidate();
      this.dialogVisible = true;
    },
    createOrUpdate() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.selCollectionFeeconfig.id) {
            const fixedPayload = {
              ...this.selCollectionFeeconfig,
              is_default: false
            };
            fixedPayload.min_count =
              fixedPayload.min_count === 0 ? null : fixedPayload.min_count;
            fixedPayload.max_count =
              fixedPayload.max_count === 0 ? null : fixedPayload.max_count;
            fixedPayload.min_total =
              fixedPayload.min_total === 0 ? null : fixedPayload.min_total;
            fixedPayload.max_total =
              fixedPayload.max_total === 0 ? null : fixedPayload.max_total;
            service
              .updateCollectionFeeConfig(
                this.selCollectionFeeconfig.id,
                fixedPayload
              )
              .then(res => {
                if (res.status === "success") {
                  this.dialogVisible = false;
                  this.$notify({
                    title: "Амжилттай",
                    message: "Амжилттай хадгалагдлаа.",
                    type: "success"
                  });
                  this.getCollectionFeeConfigList();
                } else {
                  this.$notify({
                    title: "Алдаа гарлаа",
                    message: res.message,
                    type: "warning"
                  });
                }
              });
          } else {
            service
              .createCollectionFeeConfig(this.selCollectionFeeconfig)
              .then(res => {
                if (res.status === "success") {
                  this.dialogVisible = false;
                  this.$notify({
                    title: "Амжилттай",
                    message: "Амжилттай хадгалагдлаа.",
                    type: "success"
                  });
                  this.getCollectionFeeConfigList();
                } else {
                  this.$notify({
                    title: "Алдаа гарлаа",
                    message: res.message,
                    type: "warning"
                  });
                }
              });
          }
        } else {
          this.$notify({
            title: "Алдаа",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    addNew() {
      this.selCollectionFeeconfig.min_count = "";
      this.selCollectionFeeconfig.max_count = "";
      this.selCollectionFeeconfig.min_total = "";
      this.selCollectionFeeconfig.max_total = "";
      this.selCollectionFeeconfig.percent = 10;
      this.selCollectionFeeconfig.driver_percent = 5;
      this.selCollectionFeeconfig.is_default = false;
      this.selCollectionFeeconfig.outlet_id = ["ALL"];
      this.dialogVisible = true;
    },
    deleteConfig(id) {
      this.$confirm(
        "This will permanently delete the file. Continue?",
        "Анхааруулга",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          service.deleteCollectionFeeConfig(id).then(res => {
            if (res.status === "success") {
              this.dialogVisible = false;
              this.$notify({
                title: "Амжилттай",
                message: "Амжилттай устгагдлаа.",
                type: "success"
              });
              this.getCollectionFeeConfigList();
            } else {
              this.$notify({
                title: "Алдаа гарлаа",
                message: res.message,
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
    getCollectionFeeConfigList() {
      service.getCollectionFeeConfig().then(servicesResponse => {
        this.collectionFeeConfigList = servicesResponse;
      });
      this.getAvailableOutlet();
    },
    getAvailableOutlet() {
      service.getAvailableOutlet().then(availableOutlet => {
        this.availableOutlets = [
          { name: "Бүгд", id: "ALL" },
          ...availableOutlet.data
        ];
      });
    },
    onChangeService(serviceChoose, status) {
      this.getSuggest(serviceChoose, status);
    }
  }
};
</script>
<style>
.time {
  font-size: 13px;
  color: #777;
  margin-bottom: 15px;
}
.time1 {
  font-size: 10px;
  color: #999;
  margin-bottom: 15px;
}
.tag {
  float: left;
  margin-top: 5px;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.column {
  width: 50%;
  float: left;
}
</style>
